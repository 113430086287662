module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#F6F8FE","theme_color":"#f7c0c4","display":"minimal-ui","icon":"src/images/logo/shirl-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc19e885d7f9e2c629ff58b3b9d20861"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
